import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import PanelTitle from "../../components/global/PanelTitle";
import ConfirmDelete from "../../components/common/ConfirmDelete";
import { AnnouncementsContext } from "../../context/AnnouncementsContext";
import AnnouncementForm from "../../components/announcements/AnnouncementForm";
import moment from "moment";

const AdminAnnouncements = () => {
  const { announcements, getAnnouncements } = useContext(AnnouncementsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAnnouncements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    modalComponent(
      "Agregar Aviso",
      <AnnouncementForm handleCancel={clearModal} />
    );
  };

  const handleEdit = (announcement) => {
    modalComponent(
      "Editar Aviso",
      <AnnouncementForm
        handleCancel={clearModal}
        announcement_id={announcement.announcement_id}
      />
    );
  };

  const handleDelete = (announcement) => {
    modalComponent(
      "Eliminar Aviso",
      <ConfirmDelete
        message={`¿Estás segura que deseas eliminar el aviso ${announcement.title}?`}
      />
    );
  };

  const renderAnnouncements = () => {
    if (!Array.isArray(announcements)) {
      return <div className="spinner-border" />;
    }
    if (announcements.length === 0) return <p>No hay avisos que mostrar</p>;
    return announcements.map((announcement) => (
      <div className="alert alert-primary" key={announcement.announcement_id}>
        <div className="row">
          <div className="col-10">
            <h3 className="h5">{announcement.title}</h3>
            <p className="mb-0">{announcement.content}</p>
          </div>
          <div className="col-2 text-end">
            <button
              onClick={() => handleDelete(announcement)}
              className="btn btn-sm text-muted"
            >
              <i className="fa fa-times" />
            </button>
            <button
              onClick={() => handleEdit(announcement)}
              className="btn btn-sm text-muted"
            >
              <i className="fa fa-edit" />
            </button>
            <p className="small text-muted mb-0 mt-2">{moment(announcement.start_date).format("DD MMM YYYY")}</p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <PanelTitle title="Avisos" onClick={handleCreate} />
      {renderAnnouncements()}
    </div>
  );
};

export default AdminAnnouncements;
